$grey-lighter: #e5e5e5;
$grey-light: #f7f7f8;
$grey: #e9ebec;
$grey-dark: #232f3e;
$orange: #ff9900;
$blue: #146eb4;
$red: #b41414;

$font-bg: 1.8rem;
$font-sm: 1.5rem;

@import url('https://fonts.googleapis.com/css?family=Lato:300,400,700&display=swap');
