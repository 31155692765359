@import 'layout/theme.scss';
.form-password-reset {
    display: flex;
    flex-direction: column;
    width: 500px;
    .form-password-reset__group {
        display: flex;
        flex-direction: column;
        width: 100%;
        margin-bottom: 15px;
        label {
            text-align: left;
        }
    }
    .form-password-reset__actions {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: 10px
    }
    .form-password-reset__additional-actions {
        margin-top: 10px;
    }
    .form-password-reset__link {
        text-decoration: none;
        color: $blue;
        transition: color 0.2s ease-in-out;
        &:hover {
            color: $orange;
        }
    
    }
}

.label-with-tooltip {
    display: flex;
    align-items: center;
}