@import '../../layout/theme.scss';
.login {
    display: flex;
    flex-direction: column;
    height: 100vh;

    .login__container {
        margin: 100px auto 0px auto;
        padding: 0 50px;
        height: 50vh;
        min-width: 500px;
        background: #fff;
        box-shadow: 0px 4px 50px rgba(239, 243, 244, 0.5);
        border-radius: 3px;
        position: relative;
        text-align: center;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        .login__logo {
            width: 90px;
            height: 30px;
        }

        .login__logo-placeholder {
            background-color: #fff;
            align-self: flex-start;
            position: absolute;
            top: 63px;
            height: 32px;
            width: 100%;
        }

        .login__signin-text {
            margin: 20px auto;
            text-align: center;
            line-height: 22px;
        }
        .login__input {
            display: flex;
            align-items: center;
            white-space: nowrap;
            & p {
                color: rgba(35, 47, 62, 0.5);
                margin-right: 2.1rem;
                font-size: $font-sm;
            }
            & > div > div > input {
                height: 30px;
            }
        }
    }

    .form {
        display: flex;
        flex-direction: column;
        width: 300px;
        height: 100%;
        .form__group {
            display: flex;
            flex-direction: column;
            width: 100%;
            margin-bottom: 15px;
            label {
                text-align: left;
            }
        }
        .form__actions {
            display: flex;
            justify-content: space-between;
            margin-top: 10px
        }
        .form__additional-actions {
            display: flex;
            flex-direction: column;
            align-items: flex-end;
        }
        .form__link {
            text-decoration: none;
            color: $blue;
            transition: color 0.2s ease-in-out;
            &:hover {
                color: $orange;
            }
        }
    }
    .loading-container {
        width: 300px;
    }
    .legal-text {
        margin-top: auto;
    }

}
