@import '../../layout/theme.scss';

.icon-button {
    background-color: transparent;
    border: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &__icon {
        margin-right: 0.7rem;
    }
    &__text {
        font-size: 1.5rem;
        font-family: Lato;
    }
}
