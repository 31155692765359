@import '../../layout/theme.scss';
@import '../../layout/theme.scss';
.progressGraphsWrapper {
    margin-top: 6rem;
    width: 100%;
    height: 100%;
    background-color: #ffffff;
    padding: 5rem 5.6rem;
    box-shadow: 0px 4px 50px rgba(239, 243, 244, 0.5);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    position: relative;
}

.navigation {
    width: 50%;
    display: flex;
    justify-content: center;
    margin: 3rem 0 5.4rem 0;
    &__buttons {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-bottom: 0.5rem;
    }
    &__controllers {
        display: flex;
        align-items: center;
        flex-direction: column;
    }
    &__label {
        display: inline-block;
        margin: 0 3.1rem;
        color: $blue;
        font-weight: 700;
        min-width: 15rem;
        text-align: center;
    }
    &__pointer {
        color: rgba(35, 47, 62, 0.5);
    }
}
