.footer {
    width: 100%;
    background: #f8f9fa;
    height: 3.2rem;
    border-top: 1px solid #e9ecef;
    font-size: 1.1rem;
    color: #6c757d;

    ul {
        list-style: none;
        padding-left: 0;
        margin-left: 0;
    }
    li {
        display: inline;
        border-left: 1px solid #6c757d;
        padding: 0 0.5rem;
        a {
            font-size: 1.1rem;
            color: #6c757d;
            text-decoration: none;
            padding-left: 1 rem;
        }
    }
    p {
        font-size: 1.1rem;
    }
    .footer-section-druva {
        float: left;
        padding: 1rem 1rem 1rem 2rem;
        & h4 > * {
            display: inline;
        }
        & span {
            padding-right: 5px;
        }
    }
    .copyright {
        float: right;
        padding: 1rem 2rem;
        width: 20rem;
        text-align: right;
    }
    .druva-urls {
        float: left;
    }
}
