.legal-text {
    text-align: center;
    font-size: 11px;
    line-height: 13px;
    background-color: #f8f8f8;
    color: rgba(35, 47, 62, 0.5);
    padding-bottom: 15px;
    padding-top: 15px;
    width: 100%;
}
