@import '../../../../layout/theme.scss';

.input-title {
    display: flex;
    flex-direction: row;
    background-color: $grey;
    width: 100%;
    height: 50px;
    border-right: 0.1rem solid #ffffff;
    padding: 1.5rem 0;
    justify-content: center;
    align-items: center;
    &__text {
        font-size: 1.2rem;
        font-weight: normal;
        text-align: center;
        padding: 0 0.9rem;
    }
}
