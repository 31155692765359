@import '../../layout/theme.scss';

.sources-hint, .customer-details-hint {
    width: 750px;
    margin-top: 10px;
}

.customer-details-hint {
    .section-heading{
        margin-bottom:10px;
    }

    .details-container{
        display: flex;
        width: 700px;
        justify-content: space-between;
    }
}

.select-tco {
    display: flex;
    width: 750px;
    margin-top: 10px;
    margin-bottom: 14px;
    align-items: baseline;

    span {
        margin-right: 10px;
    }

    .MuiSelect-outlined {
        padding: 8px;
        min-width: 140px;
    }
}

.sources, .customer-details {
    width: 130rem;
    display: grid;
    grid-template-areas: 'counter details dataChange retention';
    grid-template-columns: 9.8rem 1fr 1.3fr 1.7fr;
}

.source {
    grid-area: counter;
    margin-right: 0.8rem;
}

.details {
    grid-area: details;
}

.dataChange {
    grid-area: dataChange;
}

.retention {
    grid-area: retention;
}

.row {
    display: flex;
}

.details-sub-header {
    display: grid;
    grid-template-columns: 2.3fr 1fr 1fr
}

.dataChange-sub-header {
    display: grid;
    grid-template-columns: 1fr 0.9fr 0.9fr 1.2fr;
}

.add-group {
    justify-content: center;
    align-items: center;

    &__button {
        margin-top: 0.8rem;
        padding: 0.6rem 2.25rem;
        background-color: $orange;
        border-radius: 3px;
        color: #ffffff;
        font-weight: bold;
        display: flex;
        justify-content: space-around;
        align-items: center;
        font-size: 1.3rem;
        cursor: pointer;

        &:focus {
            outline: none;
        }
    }
}