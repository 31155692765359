@import '../../../layout/theme.scss';
@mixin between($first, $last) {
    &:nth-child(n + #{$first}):nth-child(-n + #{$last}) {
        @content;
    }
}
.sourceTableWrapper {
    background-color: #ffffff;
    width: 85rem;
    padding: 4.5rem 4.8rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 4px 50px rgba(239, 243, 244, 0.5);
    border-radius: 3px;
}

.sourceTable {
    border-spacing: 0;
    font-size: 1.5rem;
    width: 100%;
    thead {
        tr {
            th {
                padding: 1.2rem 2rem;
            }
            &:first-child {
                background-color: rgba($orange, 0.5);
            }
            & {
                background-color: rgba($orange, 0.2);
            }
        }
    }
    tbody {
        tr {
            text-align: center;
            background-color: #ffffff;
            transition: box-shadow 0.1s ease;
            @include between(13, 24) {
                background-color: $grey;
            }
            @include between(37, 48) {
                background-color: $grey;
            }
            &:hover {
                box-shadow: 0px 4px 20px #e9ebec;
            }
        }
        td {
            padding: 0.6rem 0;
        }
    }
}

.sources-navigation {
    display: flex;
    flex-direction: column;
    margin-right: 3.3rem;
}

.source-table-container {
    margin: 6.2rem auto;
    display: flex;
    justify-content: center;
}
