@import '../../layout/theme.scss';
.navbar {
    position: fixed;
    top: 0;
    width: 100%;
    min-width: 1100px;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: space-between;
    z-index: 99;
    padding: 1.55rem 5.3rem;
    &__logo-section {
        display: flex;
        align-items: center;
    }
    &__text {
        font-size: $font-bg;
        margin-top: 1.2rem;
    }
    &__buttons {
        width: 250px;
        display: flex;
        justify-content: space-between;

        & button {
            width: 50%;
        }

        & button > img {
            max-height: 18px;
        }
    }
    .toolbar {
        cursor: pointer;
        line-height: 18px;
        min-height: 24px;
        display: inline-flex;
        padding:0;
    }
}
.dellnavbar {
    font-size: 1.8rem;
    font-weight: 400;
    background: #0076ce;
    color: #ffffff;
    a,
    p {
        color: #ffffff;
    }
    h3 {
        border-left: 1px solid #adb5bd;
        margin-left: 1.5rem;
        padding-left: 1.5rem;
        height: 2.4rem;
        padding-top: 0;
        line-height: 2.5rem;
    }
    .clickable {
        cursor: pointer;
        line-height: 18px;
        min-height: 24px;
        padding: 0;
    }
    p.clickable img {
        float: left;
        padding-right: 0.6rem;
    }

    .navbar__buttons{
        & button{
            width: auto;
        }
    }

    .icon-button {
        &__text {
            font-size: 1.8rem;
        }
    }
}

.popover{
    padding: 1.75rem;
    &__info {
        margin-bottom: 0.5rem;
        &__email {
            color:#6C757D;
            font-size: 0.75em;
        }
    }
}