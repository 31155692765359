@import '../../layout/theme.scss';

#dialog-title {
    margin-bottom: 12px;
}

.MuiDialog-root {
    background-color: rgba(0, 0, 0, 0.7);
}

.export-dialog {
    width: 450px;
    background-color: white;
    display: flex;
    flex-direction: column;
    font-family: Lato;

    .export-dialog-content {
        flex-grow: 1;
        border-bottom: 1px solid #e9ebec;
        padding-right: 4rem;
        overflow-y: auto;

        &>span {
            color: grey;
        }

        .Mui-focused {
            color: grey;
        }

        .MuiFormControl-root {
            display: block;
            margin-top: 20px;

            label {
                font-size: 15px;
                font-family: Lato;
                min-width: 86px;
            }

            .MuiFormControlLabel-root {
                margin: 0 10px;

                span {
                    font-size: 14px;
                    font-family: Lato;
                }

                .Mui-checked {
                    color: #ff9900;
                }
            }

            .flexDirectionRow {
                flex-direction: row;
            }
        }
    }

    .export-dialog-buttons {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        height: 40px;

        a {
            text-decoration: none;
        }
    }
}