@import '../../layout/theme.scss';

.label {
    color: rgba($grey-dark, 0.5);
    font-size: $font-sm;
    font-weight: 400;
}

.value {
    color: $grey-dark;
    font-size: $font-bg;
    font-weight: 400;
}

.edit {
    border: none;
    padding: 0;
    font-family: Lato;
    font-size: 1.4rem;
    color: $orange;
    position: absolute;
    background-color: transparent;
    top: 1.9rem;
    right: 1.5rem;
}
