@import '../../../layout/theme.scss';
@mixin between($first, $last) {
    &:nth-child(n + #{$first}):nth-child(-n + #{$last}) {
        @content;
    }
}

.summaryTable {
    border-spacing: 0;
    thead {
        tr:first-child {
            background-color: rgba($orange, 0.5);
            th {
                text-align: center;
            }
            th:first-child {
                background-color: #ffffff;
            }
        }
        tr {
            background-color: rgba($orange, 0.2);
            th {
                padding: 0.5rem 0.6rem;
                font-size: $font-sm;
                text-align: left;
                font-weight: 400;
            }
        }
    }
    tbody {
        tr {
            text-align: center;
            background-color: #ffffff;
            transition: box-shadow 0.1s ease;
            @include between(13, 24) {
                background-color: $grey;
            }
            @include between(37, 48) {
                background-color: $grey;
            }
            &:hover {
                box-shadow: 0px 4px 20px #e9ebec;
            }
            td {
                padding: 1rem;
            }
        }
    }
}
//We should get the styles for each table into some separate file
.summaryTableWrapper {
    padding: 5.2rem 5.7rem;
    margin: 6rem 0rem;
    background-color: #ffffff;
    box-shadow: 0px 4px 50px rgba(239, 243, 244, 0.5);
    border-radius: 3px;
    display: flex;
    justify-content: center;
    align-items: center;
}
