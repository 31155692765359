@import '../../layout/theme.scss';

.home {
    align-self: start;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    margin: 6.5rem auto 0 auto;
    max-width: 130rem;

    .linear-stepper-wrapper {
        min-height: 77px;
        display: flex;
        justify-content: center;
    }

    .sources-form-wrapper {
        height: 100%;
        position: relative;
        flex-grow: 1;
        display: flex;
        flex-direction: column;
    }

    .MuiCircularProgress-svg {
        color: #146eb4;
    }
}
