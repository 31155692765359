@import '../../../../../layout/theme.scss';

.padding-top-40{
    padding-top: 40px;
}

.alignFlexEnd{
    align-self: flex-end;
}

.alignFlexCenter{
    align-self: center;
}

.list {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    list-style-type: none;
    &__item {
        display: flex;
        align-items: center;
    }
}
