@import '../../../../layout/theme.scss';

.sourceSummaryTable {
    border-spacing: 0;
    width: 100%;
    margin-top: 2rem;
    text-align: center;

    thead {
        tr {
            th {
                padding: 1rem 0.8rem;
                font-weight: 500;
            }
            &:first-child {
                background-color: rgba($orange, 0.5);

                th:not(:last-child) {
                    border-right: 1px solid white;
                }
            }
            &:nth-child(2) {
                th:first-child,
                th:nth-child(4),
                th:nth-child(8) {
                    border-right: 1px solid white;
                }
            }
            & {
                background-color: rgba($orange, 0.2);
            }
        }
    }
    tbody {
        tr:nth-child(even) {
            background-color: $grey-light;
        }
        tr {
            td {
                padding: 0.8rem;
            }
        }
    }
}

.sourceSummaryTableWrapper {
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    margin-bottom: 1.8rem;
    border-radius: 0.3rem;
    padding: 5.6rem;
    background-color: #ffffff;
    position: relative;
}

.source-table-edit {
    border: none;
    background-color: transparent;
    padding: 0;
    font-family: Lato;
    font-size: 14px;
    color: $orange;
    position: absolute;
    top: 5.6rem;
    right: 5.6rem;
}
